<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Kembali"
      back-button-text="Kembali"
      next-button-text="Lanjut"
      class="mb-3"
      :hide-buttons="true"
    >
      <tab-content
        title="Analisis Laporan"
        :before-change="validationFormInfo"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <analisis-laporan :localData="localData" />
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Kerjasama from '@/views/laporan/list/List.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BModal,
  BFormFile,
  BMedia,
  BMediaBody,
  BFormDatepicker,
} from 'bootstrap-vue'
import {
  uploadDokumen,
  downloadDokumen,
  tambahPihak,
  tambahPutusan,
  generateNomorPenerimaan,
} from '@/connection-api/laporan'
import { required, email } from '@validations'
import { codeIcon } from './code'
import flatPickr from "vue-flatpickr-component";
import { Indonesian } from "flatpickr/dist/l10n/id.js";
import 'flatpickr/dist/flatpickr.css';
import Korespondensi from "./component/Korespondensi.vue";
import ModalDokumen from './component/ModalDokumen.vue'
import Verifikasi from './component/Verifikasi.vue'
import Penerimaan from './component/Penerimaan.vue'
import AnalisisLaporan from './component/AnalisisLaporan.vue'
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BButton,
    flatPickr,
    BModal,
    BFormFile,
    BMedia,
    BMediaBody,
    Kerjasama,
    Korespondensi,
    ModalDokumen,
    BFormDatepicker,
    Verifikasi,
    Penerimaan,
    AnalisisLaporan,
  },
  data() {
    return {
      configs: {
        basic: {},
        wrap: {
          wrap: true,
          dateFormat: "d-m-Y",
          locale: Indonesian,
        },
      },
      centeredModalVisible: false,
      selectedContry: '',
      selectedLanguage: '',
      name: '',
      emailValue: '',
      PasswordValue: '',
      passwordCon: '',
      first_name: '',
      last_name: '',
      address: '',
      landMark: '',
      pincode: '',
      twitterUrl: '',
      facebookUrl: '',
      googleUrl: '',
      linkedinUrl: '',
      city: '',
      required,
      email,
      codeIcon,
      countryName: [
        { value: 'select_value', text: 'Select Value' },
        { value: 'Russia', text: 'Russia' },
        { value: 'Canada', text: 'Canada' },
        { value: 'China', text: 'China' },
        { value: 'United States', text: 'United States' },
        { value: 'Brazil', text: 'Brazil' },
        { value: 'Australia', text: 'Australia' },
        { value: 'India', text: 'India' },
      ],
      languageName: [
        { value: 'nothing_selected', text: 'Nothing Selected' },
        { value: 'English', text: 'English' },
        { value: 'Chinese', text: 'Mandarin Chinese' },
        { value: 'Hindi', text: 'Hindi' },
        { value: 'Spanish', text: 'Spanish' },
        { value: 'Arabic', text: 'Arabic' },
        { value: 'Malay', text: 'Malay' },
        { value: 'Russian', text: 'Russian' },
      ],
    }
  },
  props: {
    jenisPenyampaianOptions: {},
    kantorPenghubungOptions: {},
    jenisPerkaraOptions: {},
    lingkunganPeradilanOptions: {},
    provinsiOptions: {},
    kotaOptions: {},
    jenisDokumenOptions: {},
    jenisPihakTerlibatOptions: {},
    localData: {},
  },
  methods: {
    formSubmitted() {
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if(this.$route.params.readonly)
          {
            resolve(true)
          }
          else
          {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if(this.$route.params.readonly)
          {
            resolve(true)
          }
          else
          {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if(this.$route.params.readonly)
          {
            resolve(true)
          }
          else
          {
             if (success) {
                resolve(true)
              } else {
                reject()
              }
          }
        })
      })
    },
     makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        title: 'Kerjasama',
        variant,
        solid: true,
        autoHideDelay: 5000,
      })
    },
  },
}
</script>
